<!--
 * @Description: 
 * @version: 1.0
 * @Author: Sunwx
 * @Date: 2021-10-26 08:45:02
 * @LastEditors: Sunwx
 * @LastEditTime: 2021-10-26 09:46:12
-->
<template>
    <div class="report-day-class">
        <el-row>
            <el-col :span="11">
                <el-button type="primary" @click="btnClick('now')" size="mini" >今天</el-button>
                <el-button type="primary" @click="btnClick('prex')" size="mini" >前一天</el-button>
                <el-button type="primary" @click="btnClick('next')" size="mini" > 后一天</el-button>
            </el-col>
            <el-col :span="13">
                <el-form label-width="80px" :inline='true'>
                    <el-form-item>
                        <el-date-picker
                            v-model="date"
                            type="date"
                            size="mini"
                            format="yyyy 年 MM 月 dd 日"
                            placeholder="选择日期"
                            value-format="yyyy-MM-dd"
                            @change="getQueryDataFun"
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item >
                        <el-select v-model="selectAddress" placeholder="请选择"  size="mini" clearable  @change="selectAddressFun" >
                            <el-option
                                v-for="(item,key) in devicesList"
                                :key="key"
                                :label="item.title || item.uid"
                                :value="key"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <!-- <el-button type="primary" @click="query" size="mini">查询</el-button> -->
                        <el-button type="primary" @click="exprotExcel" size="mini">导出</el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
         <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane label="水位" name="1">
                <!-- 水位 -->
                <div style="margin-top:20px;" >
                    <div style="margin-bottom:10px;">水位</div>
                    <el-table :data="waterTableData_page" style="width: 100%" ref="waterTableData" border>
                        <el-table-column label="序号" width="50" prop="index" align="center" fixed>
                            <template slot-scope="scope">{{ scope.row.index + 1 }}  </template>
                        </el-table-column>
                        <el-table-column label="水电站名称" prop="title" align="center" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.title || scope.row.uid }}  
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="日均水位均值" prop="avgValue" align="center" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="最小水位" prop="minValue" align="center" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="最大水位" prop="maxValue" align="center" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="缺失小时数" prop="offlineHours" align="center" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="有效小时数" prop="validHours" align="center" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="在线率" prop="onlinePercent" align="center" :show-overflow-tooltip="true"></el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
            <el-tab-pane label="雨量" name="2">
                 <!-- 雨量 -->
                <div style="margin-top:20px;">
                    <div style="margin-bottom:10px;">雨量</div>
                    <el-table :data="rainfallTableData_page" style="width: 100%" ref="rainfallTableData" border>
                        <el-table-column label="序号" width="50" prop="index" align="center" fixed>
                            <template slot-scope="scope">{{ scope.row.index + 1 }}  </template>
                        </el-table-column>
                       <el-table-column label="水电站名称" prop="title" align="center" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                <div>
                                    {{ scope.row.title || scope.row.uid }}  
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="日均雨量均值" prop="avgValue" align="center" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="最小水位" prop="minValue" align="center" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="最大水位" prop="maxValue" align="center" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="缺失小时数" prop="offlineHours" align="center" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="有效小时数" prop="validHours" align="center" :show-overflow-tooltip="true"></el-table-column>
                        <el-table-column label="在线率" prop="onlinePercent" align="center" :show-overflow-tooltip="true"></el-table-column>
                    </el-table>
                </div>
            </el-tab-pane>
          
        </el-tabs>
        <div class="block nextpage">
            <el-pagination @size-change="handleSizeChange" 
                @current-change="handleCurrentChange" 
                :current-page="currentPage" 
                :page-sizes="[8]" 
                :page-size="pagesize" 
                layout="total, sizes, prev, pager, next, jumper" 
                :total=" Number(activeName) == 1 ? waterTableData.length : rainfallTableData.length ">
            </el-pagination>
        </div>
    </div>
</template>
<script>
// 统计报表【日报】
export default {
    props:{
        // 设备信息
        deviceData:{
            type:Object,
            default:()=>{}
        },
        // 设备列表
        devicesList:{
            type:Array,
            default:()=>[]
        }
    },
    data() {
        return {
            // 水位
            waterTableData: [],
            waterTableData_page:[],
            // 雨量
            rainfallTableData:[],
            rainfallTableData_page:[],
            total:0,
            currentPage: 1, //初始页
            pagesize: 8, //每页的数据
            date:'', // 日期,
            selectAddress:'', // 地址选择
            // 获取数据
            getDatas:[],
            activeName:'1',
        };
    },
    components: {
    },
    created() {
        // 赋值日期
        this.date = this.common.getCurrentDate();
        this.changeOnMsg()
    },
    watch: {
        deviceData(newval,val){
            this.initialization()
        },
    },
    mounted(){
      if(this.devicesList.length > 0) this.getdataFun()
    },
    methods: {
        // 初始化
        initialization(){
            // 重新获取
           this.currentPage = 1
           this.pagesize = 8
           this.date = this.common.getCurrentDate()
           this.selectAddress = ''
           this.getdataFun()
        },
        // 标签切换
        handleClick(tab, event) {
            this.initialization()
        },
        // 选择
        selectAddressFun(e){
            if(e === '') return this.initialization()
            let item = this.devicesList[e]
            this.waterTableData = []
            this.rainfallTableData =[]
            this.waterTableData_page =  []
            this.rainfallTableData_page = []
            if(Object.keys(this.getDatas).length == 0) return false;
           if(Number(this.activeName) == 1) {
               // 水位
                let waterObj = {
                    ...item,
                    index:e,
                    ...(this.getDatas[item.uid].hasOwnProperty('water') ? this.getDatas[item.uid].water : {})
                }
                this.waterTableData_page = [waterObj]
           }else{
               // 雨量
                let rainfallObj = {
                    ...item,
                    index:e,
                    ...(this.getDatas[item.uid].hasOwnProperty('rainFall') ? this.getDatas[item.uid].rainFall : {})
                }
                this.rainfallTableData_page = [rainfallObj]
           }
        },
        // 请求日报
        getdataFun(){
            if(!this.date){
                this.date = this.common.getCurrentDate();
            }
            let date = this.date.replace(/\-/g,'') || ''
            let json = {
                "cmd": "reportForm",
                "type":"day",   //day,week,season,year,分别对应日报，周报，季报和年报
                "uid": this.deviceData.uid || '',//uid为空则返回当前用户所有设备的报表，否则只返回指定uid的报表
                "date":date, // 日期
                "selectOptions_val":this.selectOptions_val
              };
            console.log('日报请求',json)
            this.ws.send(json);
        },
        // 监听
        changeOnMsg(){
            // 获取数据
            this.ws.addCallback('reportFormAck', (res) => {
                this.getDatas = res.items
                this.waterTableData = []
                this.rainfallTableData =[]
                this.waterTableData_page =  []
                this.rainfallTableData_page = []
                let arrItmes = Object.keys(res.items);
                
                if( arrItmes.length  > 0){
                    // 这里要进行拆分
                    arrItmes.forEach((items,key,elemnt) => {
                        let  [ devInfo ]= this.devicesList.filter(item=>item.uid == items)
                        if(devInfo){
                            let waterObj = {
                                ...devInfo,
                                index:key,
                                ...(this.getDatas[devInfo.uid].hasOwnProperty('water') ? this.getDatas[devInfo.uid].water : {}) 
                            }
                            let rainfallObj = {
                                ...devInfo,
                                index:key,
                                ...(this.getDatas[devInfo.uid].hasOwnProperty('rainFall') ? this.getDatas[devInfo.uid].rainFall :{})
                            }
                            // 水位
                            this.waterTableData.push(waterObj)
                            // 雨量
                            this.rainfallTableData.push(rainfallObj)
                        }
                    });
                    this.waterTableData_page =  this.waterTableData.concat()
                    this.rainfallTableData_page =  this.rainfallTableData.concat()
                    // 分页
                    this.total = Math.ceil(arrItmes.length / this.pagesize)
                    let s = 0;                  // 起始索引
                    let n = this.pagesize;      // 结束索引
                    // 当前页数
                    if(this.currentPage > 1){
                       s = (this.currentPage - 1) * (this.pagesize )
                       n =  s + this.pagesize
                    }
                    this.waterTableData_page = this.waterTableData_page.slice(s,n)
                    this.rainfallTableData_page = this.rainfallTableData_page.slice(s,n)
                }
                console.log('水位',this.waterTableData);
               
            })
            // 获取设备
        //     this.ws.addCallback('devicesAck', (res) => {
        //        this.devicesList = res.devices 
        //        // 请求
        //        this.getdataFun()
        //    })
        },
        // 选择日期
        getQueryDataFun(){
            this.selectAddress =''
            this.getdataFun()
        },
        /**
         * @description: 切换页数
         * @param {*} val
         * @return {*}
         * @author: Sunwx
         */        
        handleSizeChange(val) {
            this.pagesize = val;
            this.getdataFun()
        },
        /**
         * @description: 切换页码
         * @param {*} val
         * @return {*}
         * @author: Sunwx
         */        
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getdataFun()
        },
        /**
         * @description: 按钮事件
         * @param {*} type
         * @return {*}
         * @author: Sunwx
         */        
        btnClick(type){
            switch(type){
                case "now":
                    this.date = this.common.getCurrentDate();
                    break;
                case "prex":
                    this.date = this.getBefOrAft(0,this.common.getCurrentDate());
                    break;
                case "next":
                    this.date = this.getBefOrAft(1,this.common.getCurrentDate());
                    break;
            }
            this.getdataFun()
        },
        // 获取前天或者后一天  type: 0: 前一天 1：后一天
        getBefOrAft(type,date){
                let forMatDate = date.replace(/-/g, '/')  // 格式化日期 2019-02-23 => 2019/02/23
                let d = new Date(forMatDate)    // new Date() 不能识别 2019-01-02  需转化为 2019/01/02
                if(type === 0){
                    d = +d - 1000*60*60*24
                }else if(type === 1){
                    d = +d + 1000*60*60*24  // d前面需要加上 +
                }
                d = new Date(d) 
                return this.dateFormat(d)  // 格式化 将日期格式转化为我们需要展示格式
        },
        // 格式化日期，传入日期对象   =>2019-01-11  
        dateFormat(date){
            let y = date.getFullYear()
            let m = date.getMonth() + 1
            let d = date.getDate()
            if (m <= 9) {
            m = '0' + m
            }
            if (d <= 9) {
            d = '0' + d
            }
            return y + '-' + m + '-' + d
        },
        /**
         * @description: 查询
         * @param {*}
         * @return {*}
         * @author: Sunwx
         */        
        query(){
            this.getdataFun()
        },
        /**
         * @description: 导出
         * @param {*}
         * @return {*}
         * @author: Sunwx
         */        
        exprotExcel(){
            alert('导出');
        }
    }
}
</script>
<style>
.report-day-class{
    width: 100%;
}

.input-with-select .el-input-group__prepend {
    background-color: #fff;
}
</style>

